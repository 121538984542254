














import {Component, Prop, Vue} from 'vue-property-decorator';
import SnapshotImportAssets from '@/components/snapshot/import/SnapshotImportAssets.vue';
import SnapshotSaveImportModel from '@/components/snapshot/import/models/SnapshotSaveImportModel.vue';

@Component({
  components: {SnapshotSaveImportModel, SnapshotImportAssets},
})
export default class SnapshotsImport extends Vue {
  @Prop(Object) private data!: any;
  @Prop({type: Array, default: () => []}) private errors!: string[];

  get branchesData(): any[] {
    return this.data.branches;
  }

  get assets(): any[] {
    return this.data.objects;
  }

  get filename(): any {
    return this.data.filename;
  }
}
