var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"snapshot-import-assets"},[_c('div',{staticClass:"import-header row"},[_c('div',{staticClass:"col-auto back"},[_c('i',{staticClass:"fa fa-chevron-left",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.$emit('backPress')}}})]),_c('div',{staticClass:"col"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$it('global.imports', 'Imports'))+" "),_c('span',{staticClass:"items-amount"},[_vm._v("("+_vm._s(_vm.data.length)+" items)")]),_c('span',{staticClass:"amount-waring",attrs:{"aria-label":_vm.incorrectWarning,"data-balloon-pos":"down"}},[_vm._v(_vm._s(_vm.$it('global.import-amount.incorrect', 'Incorrect?')))])])]),_c('div',{staticClass:"col-auto save"},[_vm._t("save",[_c('button',{staticClass:"btn btn-outline-success",attrs:{"disabled":!_vm.canSaveBranches},on:{"click":function($event){return _vm.$refs.saveImport.open()}}},[_vm._v(" "+_vm._s(_vm.$it('global.save', 'Save'))+" ")]),_c('snapshot-save-model',{ref:"saveImport",attrs:{"filename":_vm.filename,"branches":_vm.branches}})],{"canSaveBranches":_vm.canSaveBranches,"filename":_vm.filename,"branches":_vm.branches})],2)]),(_vm.errors.length > 0)?_c('div',{staticClass:"alert alert-danger"},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_vm._l((_vm.branches),function(branch,index){return [_c('data-table',{staticClass:"assets-datatable",class:{'datatable-border-bottom': !branch.extend && (index + 1) !== _vm.branches.length},attrs:{"title":(branch.address) ? branch.address.name : 'Assets',"data":branch.assets,"columns":_vm.columns,"recycle-scroller":false,"bulkChangeItems":_vm.bulkChangeAssetIds,"fieldsBeingChanged":_vm.fieldsBeingChanged,"extend":branch.extend},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var total = ref.total;
var title = ref.title;
return [_c('div',{staticClass:"row branch-header"},[_c('div',{staticClass:"col-auto pointer"},[_c('button',{staticClass:"btn btn-outline-primary toggle-visible",on:{"click":function($event){branch.extend = !branch.extend}}},[(branch.extend)?_c('i',{staticClass:"hide-assets fas fa-chevron-down"}):_c('i',{staticClass:"show-assets fas fa-chevron-up"})])]),_c('div',{staticClass:"col no-gutters pointer",on:{"click":function($event){branch.extend = !branch.extend}}},[_c('h2',[_vm._v(_vm._s(title))])]),_c('div',{staticClass:"col-auto"},[_c('snapshot-select-inspector-modal',{attrs:{"balloon-positioning":_vm.balloonPositioning},model:{value:(_vm.branches[index]),callback:function ($$v) {_vm.$set(_vm.branches, index, $$v)},expression:"branches[index]"}})],1)])]}},{key:"name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-title"},[_vm._v(" "+_vm._s(value)+" "),(item.similarAssets.length > 0)?[(item.automaticallyChanged)?_c('span',{staticClass:"badge badge-pill badge-warning"},[_vm._v(" "+_vm._s(_vm.$it('snapshot.import.automatically-changed.warning', 'Automatically changed'))+" ")]):_vm._e(),(_vm.bulkChangeAssetIds.includes(item.id.toString()))?_c('span',{staticClass:"badge badge-pill badge-intrador"},[_vm._v(" "+_vm._s(_vm.$it('snapshot.import.bulk.update.warning', 'Similar Item'))+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"row-subtitle"},[_c('span',{attrs:{"aria-label":_vm.$it('global.vendor-invoice-number', 'Vendor Invoice Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.meta.vendor_invoice_number))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.contract-number', 'Contract Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.meta.contract_number))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(item.serialNumber))])])]}},{key:"group",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('group',{ref:"groupField",refInFor:true,attrs:{"text":item.group.name},on:{"itemSelected":function (groupInputField) { return _vm.onNewGroupSelected(groupInputField, item); },"focus":function($event){_vm.focus=true},"blur":function($event){_vm.focus=false}},model:{value:(item.group.id),callback:function ($$v) {_vm.$set(item.group, "id", $$v)},expression:"item.group.id"}})]}},{key:"make",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('make',{attrs:{"clearable":false},on:{"input":function($event){return _vm.fieldChanged(item, 'make')},"focus":function($event){_vm.focus=true},"blur":function($event){_vm.focus=false}},model:{value:(item.make),callback:function ($$v) {_vm.$set(item, "make", $$v)},expression:"item.make"}})]}},{key:"type",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('type',{attrs:{"make":item.make},on:{"input":function($event){return _vm.fieldChanged(item, 'type')},"focus":function($event){_vm.focus=true},"blur":function($event){_vm.focus=false}},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"status",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.errors.length === 0)?_c('i',{staticClass:"fas fa-check-circle"}):_c('div',{attrs:{"aria-label":item.errors.join('\n'),"data-balloon-pos":"left"}},[_c('i',{staticClass:"fas fa-exclamation-circle"})])]}},{key:"row-addition",fn:function(ref){
var item = ref.item;
return [(_vm.similarItemsUpdateMode(item))?_c('div',{staticClass:"similar-assets-update-operation-menu"},[_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"btn btn-apply-all",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeSimilarItemsField(item)},"mouseenter":function($event){_vm.mouseover=true},"mouseleave":function($event){_vm.mouseover=false}}},[_c('i',{staticClass:"fas fa-copy"}),_vm._v(" "+_vm._s(_vm.$it('snapshot.import.bulk.update.apply.all', 'Apply to all'))+" "),(_vm.bulkChangeAssetIds.includes(item.id.toString()))?_c('span',{staticClass:"badge badge-pill badge-intrador"},[_vm._v(" "+_vm._s(_vm.$it('snapshot.import.bulk.update.warning.plural', 'Similar Items'))+" ")]):_vm._e(),_vm._v(" ("+_vm._s(_vm.countSimilarItems-1)+") ")]),_c('button',{staticClass:"btn btn-apply-single",attrs:{"type":"button"},on:{"click":_vm.cancelChangingItems,"mouseenter":function($event){_vm.mouseover=true},"mouseleave":function($event){_vm.mouseover=false}}},[_c('i',{staticClass:"fas fa-arrow-down"}),_vm._v(" "+_vm._s(_vm.$it('snapshot.import.bulk.update.apply.singleitem', 'Apply only to this item'))+" ")])])]):_vm._e()]}}],null,true)})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }