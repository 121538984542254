












































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import {InspectionInput} from '@/types/intrador';
// @ts-ignore
import {SnapshotImportAsset, SnapshotImportBranch} from '@/components/snapshot/import/SnapshotImportAssets.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import SaveModelMixin from '@/components/snapshot/import/models/SaveModelMixin';

@Component({
  components: {InputSelect, InputText, SpinnerButton, Modal},
})
export default class SnapshotSaveModel extends Mixins(SaveModelMixin) {
  @Prop(String) private filename?: string;
  private trusted: boolean = false;

  private async save(spinning: (state?: boolean) => boolean) {
    if (this.canSave && this.plannedAt !== null) {
      this.saving = spinning();

      const inspections: any[] = [];
      this.branches.forEach((branch: SnapshotImportBranch) => {
        branch.assets.forEach((asset: SnapshotImportAsset) => {
          const inspection: InspectionInput = {
            inspectionType: {id: this.importInspectionTypeId.toString()},
            // Planned at cannot be null because it will be checked in the getter canSave
            plannedAt: this.resolveAsUTCDate(this.plannedAt!).toISOString(),
            // Due at cannot be null because it will be checked in the getter canSave
            dueAt: this.resolveAsUTCDate(this.dueAt!).toISOString(),
            address: {
              id: branch.id,
            },
            inspectorEmail: null,
            inspector: null,
            parameterValues: asset.parameterValues,
            asset: {
              id: asset.id,
            },
            trusted: this.trusted,
          };

          if (this.groupRequired) {
            inspection.asset.group = {
              id: asset.group.id,
            };
          }

          if (this.makeTypeRequired) {
            inspection.asset.make = asset.make;
            inspection.asset.type = asset.type;
          }

          if (this.$validateEmail(branch.inspectorId!)) {
            inspection.inspectorEmail = branch.inspectorId!.trim().toLowerCase();
          } else {
            inspection.inspector = {id: branch.inspectorId!};
          }

          inspections.push(inspection);
        });
      });

      // Add the percentage that need te be autited
      const variables: any = {inspections, percentageOfInspections: this.percentageOfInspections};
      if (this.filename) {
        variables.filename = this.filename;
      }

      const result = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateInspections.gql'),
        variables,
      }).catch((error) => {
        this.error = error.message;
      });

      this.saving = spinning(false);

      if (result) {
        const params: any = {};

        const createdSnapshots = result.data.createInspections.data.length;
        if (createdSnapshots < this.numberOSnapshots) {
          params.errors = result.data.createInspections.errors;
        }
        if (createdSnapshots > 0) {
          params.success = this.$it('snapshots.import.success', {part: createdSnapshots, total: this.totalSnapshots}, 'The snapshots are successfully imported ({part}/{total})') as string;
        }

        this.$router.push({name: 'inspections', params});
      }
    }
  }
}
