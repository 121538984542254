var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('uploader',{staticClass:"snapshot-import",attrs:{"id":"snapshot-import","url":_vm.url,"hideUploaderOnDataReceived":true,"acceptedFiles":['.xls','.xlsx','.csv']},scopedSlots:_vm._u([{key:"thumbs",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('div',{staticClass:"alert alert-danger"},[_c('ul',_vm._l((errors),function(error){return _c('li',[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()]}},{key:"default",fn:function(ref){
var loading = ref.loading;
var uploads = ref.uploads;
var errors = ref.errors;
var clear = ref.clear;
return [(_vm.newImport)?_c('snapshot-import',{attrs:{"data":uploads[0].data,"errors":errors},on:{"backPress":clear}}):_c('snapshot-import-assets',{attrs:{"data":uploads[0].data,"filename":uploads[0].filename,"errors":errors,"balloon-positioning":"left"},on:{"backPress":clear}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }