

































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import SnapshotSaveModel from '@/components/snapshot/import/models/SnapshotSaveModel.vue';
import SnapshotSelectInspectorModal from '@/components/snapshot/import/models/SnapshotSelectInspectorModal.vue';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import DataTable from '@/layouts/back-office/elements/datatable/DataTable.vue';
import ComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import Group from '@/layouts/back-office/elements/input/InputAssetGroup.vue';
import Make from '@/layouts/back-office/elements/input/InputMake.vue';
import Type from '@/layouts/back-office/elements/input/InputType.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import {InspectionParameterValueInput} from '@/types/intrador';

export interface SnapshotImportGroup {
  id: string;
  name: string;
}

export interface SnapshotImportAsset {
  id: string;
  name: string;
  make: string | null;
  type: string | null;
  serialNumber: string | null;
  group: SnapshotImportGroup;
  parameterValues?: InspectionParameterValueInput[];
  meta: any;
  errors: string[];
  automaticallyChanged: boolean;
  similarAssets: SnapshotImportAsset[];
}

export interface SnapshotImportBranch {
  id?: string;
  address?: any;
  inspectorId: string | null;
  inspectorName: string | null;
  inspectorEmail: string | null;
  extend: boolean;
  assets: SnapshotImportAsset[];
}

@Component({
  components: {
    InputInspector,
    Type, Make, Group, QueryAutocomplete, ComboSelect, DataTable,
    SnapshotSaveModel, SnapshotSelectInspectorModal, SpinnerButton, Modal,
  },
})

export default class SnapshotImportAssets extends Vue {
  @Prop(Array) private branchesData?: any[];
  @Prop(Array) private data!: any[];
  @Prop(String) private filename!: string;
  @Prop({type: Array, default: () => []}) private errors!: string[];
  @Prop({type: String, default: 'right'}) private balloonPositioning!: string;

  private branches: SnapshotImportBranch[] = [];
  private bulkChangeAssetIds: string[] = [];
  private groupRequired = true;
  private makeTypeRequired = true;
  private assetIdCurrentlyBeingChanged: string = '';
  private fieldsBeingChanged: string[] = [];
  private focus: boolean = false;
  private mouseover: boolean = false;


  get columns(): DataTableColumn[] {
    let columns: DataTableColumn[] = [
      {key: 'id', title: '#', class: 'data-table-col-fixed', width: 70},
      {key: 'name', title: 'Asset', class: 'data-table-dominance-2'},
    ];

    if (this.groupRequired) {
      columns.push({key: 'group', title: 'Group'});
    }

    if (this.makeTypeRequired) {
      columns.push({key: 'make', title: 'Make'});
      columns.push({key: 'type', title: 'Type'});
    }

    columns = columns.concat([
      {key: 'status', title: 'Status', class: 'data-table-col-fixed data-table-col-center', width: 90},
    ]);

    return columns;
  }

  get incorrectWarning(): string {
    if (this.$store.getters.user.entity.id === '883') {
      return this.$it('global.import.check-file', 'Please check you file if a correct layout is uploaded') as string;
    }

    return this
      .$it('global.import.empty-line', 'Check for an empty line, because the import will stop on an empty line')
      .toString();
  }

  private getInspector(address: any) {
    let inspector = null;

    if (address.last_inspector) {
      address.last_inspector.id = address.last_inspector.uid;

      return address.last_inspector;
    }

    if (address.email) {
      return {
        id: address.email,
        email: address.email,
      };
    }

    if (!address.users) {
      return null;
    }

    address.users = address.users.map((user: any) => {
      user.id = user.uid;
      return user;
    });

    if (address.users.length >= 1) {
      inspector = address.users[0];
    }

    return inspector;
  }

  private getBranchesFromBranchDataData(): SnapshotImportBranch[] {
    if (!this.branchesData) {
      return [];
    }

    return this.branchesData.map((address: any, index: any): SnapshotImportBranch => {
      const id = address.aid.toString();
      const inspector = this.getInspector(address);

      return {
        id,
        address,
        inspectorId: (inspector) ? inspector.id : null,
        inspectorName: (inspector)
          ? (inspector.name)
            ? inspector.name
            : inspector.email
          : null,
        inspectorEmail: null,
        extend: (index === 0),
        assets: [],
      };
    });
  }

  private getBranchesFromData(): SnapshotImportBranch[] {
    return this.data.reduce((branches: SnapshotImportBranch[], asset: any): SnapshotImportBranch[] => {
      const address = asset.address;
      if (!address) {
        return branches;
      }

      const id = address.aid.toString();

      let branch = branches.find((b: any) => b.id === id);

      if (!branch) {
        const inspector = this.getInspector(address);

        branch = {
          id,
          address,
          inspectorId: (inspector) ? inspector.id : null,
          inspectorName: (inspector)
            ? (inspector.name)
              ? inspector.name
              : inspector.email
            : null,
          inspectorEmail: null,
          extend: (branches.length === 0),
          assets: [],
        };

        branches.push(branch);
      }

      return branches;
    }, []);
  }

  @Watch('data', {immediate: true})
  private generateBranches(data: any[]) {
    this.groupRequired = this.$store.getters.user.entity.config.snapshotImportGroupRequired;
    this.makeTypeRequired = this.$store.getters.user.entity.config.snapshotImportMakeTypeRequired;

    const assets: SnapshotImportAsset[] = [];
    this.branches = this.getBranchesFromBranchDataData();
    if (this.branches.length === 0) {
      this.branches = this.getBranchesFromData();
    }

    const noAssetBranch: SnapshotImportBranch = {
      inspectorId: null,
      inspectorName: null,
      inspectorEmail: null,
      extend: true,
      assets: [],
    };

    data.forEach((asset: any) => {
      const address = asset.address;

      let branch: SnapshotImportBranch|undefined;
      if (address) {
        const id = address.aid.toString();
        branch = this.branches.find((b: any) => b.id === id);
      }

      let parameters: InspectionParameterValueInput[] = [];
      if ('parameters' in asset) {
        parameters = asset.parameters.map((parameter: any) => {
          const parameterValue: InspectionParameterValueInput = {
            id: parameter.opid,
            value: parameter.value,
            parameter: {
              id: parameter.parameter_pid,
            },
          };
          return parameterValue;
        });
      }

      const branchAsset: SnapshotImportAsset = {
        id: asset.oid,
        name: asset.name,
        make: asset.make,
        type: asset.type,
        serialNumber: asset.serial_number,
        parameterValues: parameters,
        group: {
          id: asset.group_gid,
          name: asset.group_name,
        },
        meta: asset.meta,
        errors: [],
        automaticallyChanged: false,
        similarAssets: [],
      };

      this.calculateErrors(branchAsset);

      assets.filter((similarAsset: SnapshotImportAsset) => similarAsset.name === branchAsset.name)
        .forEach((similarAsset: SnapshotImportAsset) => {
          branchAsset.similarAssets.push(similarAsset);
          similarAsset.similarAssets.push(branchAsset);
        });

      assets.push(branchAsset);

      if (branch) {
        branch.assets.push(branchAsset);
      } else {
        noAssetBranch.assets.push(branchAsset);
      }
    });

    if (noAssetBranch.assets.length > 0) {
      this.branches.unshift(noAssetBranch);
    }
  }

  get canSaveBranches() {
    return this.branches.every((branch: SnapshotImportBranch) => {
      return branch.inspectorId &&
        branch.assets.every((asset: SnapshotImportAsset) => {
          return (asset.group.id || !this.groupRequired) && ((asset.make && asset.type) || !this.makeTypeRequired);
        });
    });
  }

  get countSimilarItems() {
    return this.bulkChangeAssetIds.length;
  }

  private onNewGroupSelected(groupInputField: any, currentItem: SnapshotImportAsset) {
    currentItem.group.name = groupInputField.name;
    currentItem.group.id = groupInputField.id;
    this.fieldChanged(currentItem, 'group');
  }

  private similarItemsUpdateMode(item: SnapshotImportAsset) {
    return this.assetIdCurrentlyBeingChanged === item.id.toString() && item.similarAssets.length >= 1;
  }

  private fieldChanged(asset: SnapshotImportAsset, fieldType: string) {
    this.calculateErrors(asset);

    // if we are currently not already changing an item
    if (this.isNotSimilarItemChangeMode()) {
      this.assetIdCurrentlyBeingChanged = asset.id.toString();

      // log which field we are changing
      if (!this.fieldsBeingChanged.includes(fieldType)) {
        this.fieldsBeingChanged.push(fieldType);
      }

      if (!this.bulkChangeAssetIds.includes(asset.id.toString())) {
        this.bulkChangeAssetIds.push(asset.id.toString());
      }

      asset.similarAssets.forEach((similarAsset: SnapshotImportAsset) => {
        if (!this.bulkChangeAssetIds.includes(similarAsset.id.toString())) {
          this.bulkChangeAssetIds.push(similarAsset.id.toString());
        }
      });
    }
  }

  @Watch('focus')
  private onFocusChanged(event: any, test: any) {
    if (!this.focus) {
      // we lost focus on the inputfield so we end the "bulk edit mode"
      if (!this.mouseover) {
        this.cancelChangingItems();
      }
    }

    // exception for the group input field. We lose focus on this field, since its is a combo-select.
    // We hence do not have focus on the original input element and cannot cancelChaningItems on focuschange
    // TODO: fix
    if (this.fieldsBeingChanged.includes('group')) {
      this.cancelChangingItems();
    }
  }

  private changeSimilarItemsField(asset: SnapshotImportAsset) {
    asset.similarAssets.forEach((similarAsset: SnapshotImportAsset) => {

      if (this.fieldsBeingChanged.includes('make')) {
        similarAsset.make = asset.make;
      }

      if (this.fieldsBeingChanged.includes('type')) {
        similarAsset.type = asset.type;
      }

      if (this.fieldsBeingChanged.includes('group')) {
        similarAsset.group.id = asset.group.id;
        similarAsset.group.name = asset.group.name;
      }

      this.calculateErrors(similarAsset);
    });

    this.cancelChangingItems();
  }

  private cancelChangingItems() {
    this.bulkChangeAssetIds = [];
    this.assetIdCurrentlyBeingChanged = '';
    this.fieldsBeingChanged = [];
  }

  private isNotSimilarItemChangeMode() {
    // if below items are set accordingly, we are in 'similar items change mode'
    return this.assetIdCurrentlyBeingChanged === '' && this.bulkChangeAssetIds.length <= 0;
  }

  private calculateErrors(asset: SnapshotImportAsset) {
    const messages: string[] = [];

    if (this.groupRequired && asset.group.id === null) {
      messages.push(this.$it('snapshots.import.group.not-selected', 'There is no group selected') as string);
    }

    if (this.makeTypeRequired && (asset.make === null || asset.make.length <= 0)) {
      messages.push(this.$it('snapshots.import.make.not-selected', 'There is no make selected') as string);
    }

    if (this.makeTypeRequired && (asset.type === null || asset.type.length <= 0)) {
      messages.push(this.$it('snapshots.import.type.not-selected', 'There is no make selected') as string);
    }

    asset.errors = messages;
  }
}
