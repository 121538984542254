































import {Component, Vue} from 'vue-property-decorator';
import Uploader from '@/layouts/back-office/elements/upload/Uploader.vue';
import SnapshotImportAssets from '@/components/snapshot/import/SnapshotImportAssets.vue';
import SnapshotImport from '@/components/snapshot/import/SnapshotImport.vue';

@Component({
  components: {SnapshotsImport, SnapshotImport, SnapshotImportAssets, Uploader},
})
export default class SnapshotsImport extends Vue {

  get newImport(): boolean {
    return Number(this.$store.getters.user.entity.config.snapshotImporterVersion) === 3;
  }

  get url(): string {
    return (this.newImport)
      ? '/inspection/imports/assets'
      : '/inspection/assets/_import';
  }

}
