import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
// @ts-ignore
import {SnapshotImportBranch} from '@/components/snapshot/import/SnapshotImportAssets.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import {InspectionType, InspectionType_inspectionType} from '@/types/intrador';

@Component
export default class FilterMixin extends Vue {
  @Prop(Array) protected branches!: SnapshotImportBranch[];

  protected saving: boolean = false;
  protected error: string | null = null;

  protected date: DateRange | null = null;
  protected plannedAt: Date | null = null;
  protected dueAt: Date | null = null;
  protected percentageOfInspections: number = 100;

  protected inspectionType: InspectionType_inspectionType | null = null;

  public created() {
    this.$apollo.query<InspectionType>({
      query: require('@/graphql/queries/inspection-type/InspectionType.gql'),
      variables: {id: this.importInspectionTypeId},
    }).then((data) => {
      this.inspectionType = data.data.inspectionType;
    });
  }

  get canSaveBranches() {
    return this.branches.every((branch: any) => {
      return branch.inspectorId &&
        branch.assets.every((asset: any) => {
          return (asset.group.id || !this.groupRequired) && ((asset.make && asset.type) || !this.makeTypeRequired);
        });
    });
  }

  get canSave() {
    return this.canSaveBranches && this.plannedAt && this.dueAt;
  }

  get importInspectionTypeId() {
    return this.$store.getters.user.entity.config.snapshotImporterInspectionType;
  }

  get inspectionStartTime() {
    return this.inspectionType?.startTime?.slice(0, -3) ?? this.$store.getters.user.entity.config.inspectionStartTime;
  }

  get inspectionEndTime() {
    return this.inspectionType?.dueTime?.slice(0, -3) ?? this.$store.getters.user.entity.config.inspectionStartTime;
  }

  get groupRequired() {
    return this.$store.getters.user.entity.config.snapshotImportGroupRequired;
  }

  get makeTypeRequired() {
    return this.$store.getters.user.entity.config.snapshotImportMakeTypeRequired;
  }

  get totalSnapshots() {
    return this.branches.reduce((total, branch) => total + branch.assets.length, 0);
  }

  get numberOSnapshots() {
    return this.branches.reduce((total, branch) => {
      const percentage = branch.assets.length * (this.percentageOfInspections / 100);
      const amount = (this.percentageOfInspections < 50) ? Math.ceil(percentage) : Math.round(percentage);

      return total + amount;
    }, 0);
  }

  public open() {
    const modal = this.$refs.saveImport as Modal;

    // @ts-ignore
    modal.open();
  }

  @Watch('date')
  protected dateChange(date: DateRange | null) {
    this.plannedAt = (date) ? date.start : null;
    this.dueAt = (date) ? date.end : null;
  }

  protected resolveAsUTCDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0));
  }
}
