












































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
// @ts-ignore
import {SnapshotImportBranch} from '@/components/snapshot/import/SnapshotImportAssets.vue';
import {CreateInspectionsFromImportVariables} from '@/types/intrador';
import SaveModelMixin from '@/components/snapshot/import/models/SaveModelMixin';

@Component({
  components: {InputSelect, InputText, SpinnerButton, Modal},
})
export default class SnapshotSaveImportModel extends Mixins(SaveModelMixin) {
  @Prop(Object) private import!: any;
  private trusted: boolean = false;

  private async save(spinning: (state?: boolean) => boolean) {
    if (this.canSave && this.plannedAt !== null) {
      this.saving = spinning();

      const variables: CreateInspectionsFromImportVariables = {
        id: this.import.iid,

        percentageOfInspections: this.percentageOfInspections,

        // Planned at cannot be null because it will be checked in the getter canSave
        plannedAt: this.resolveAsUTCDate(this.plannedAt!).toISOString(),

        // Due at cannot be null because it will be checked in the getter canSave
        dueAt: this.resolveAsUTCDate(this.dueAt!).toISOString(),

        inspectors: [],

        trusted: this.trusted,
      };

      variables.inspectors = this.branches.map((branch: SnapshotImportBranch) => {
        const branchVariables: any = {
          id: branch.id,
        };

        if (this.$validateEmail(branch.inspectorId!)) {
          branchVariables.inspectorEmail = branch.inspectorId!.trim().toLowerCase();
        } else {
          branchVariables.inspector = {id: branch.inspectorId!};
        }

        return branchVariables;
      });

      const result = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateInspectionsFromImport.gql'),
        variables,
      }).catch((error) => {
        this.error = error.message;
      });

      this.saving = spinning(false);

      if (result) {
        const params: any = {};

        const createdSnapshots = result.data.createInspectionsFromImport.data.length;
        if (createdSnapshots < this.numberOSnapshots) {
          params.errors = result.data.createInspectionsFromImport.errors;
        }
        if (createdSnapshots > 0) {
          params.success = this.$it('snapshots.import.success', {part: createdSnapshots, total: this.totalSnapshots}, 'The snapshots are successfully imported ({part}/{total})') as string;
        } else {
          params.success = this.$it('snapshots.import.pending', 'We are importing the snapshot. This can take a few minutes') as string;
        }

        this.$router.push({name: 'inspections', params});
      }
    }
  }
}
